import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Divider, Dropdown, Layout, Menu } from 'antd';
import { Outlet } from 'react-router-dom';
import { useNavigate, useRoutes, useLocation } from 'react-router-dom';
import { FaUser, FaSignOutAlt, FaArrowLeft, FaCaretDown } from 'react-icons/fa';
import { Modal } from 'antd';
import {
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { MdPeople } from 'react-icons/md';
import { router } from '../index';
const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const MainLayout = () => {
  const navigate = useNavigate();

  return (<>
      <Layout>
        <Layout>
            <Content>
              <Outlet />
            </Content>
        </Layout>
      </Layout>
  </>
  );
}

export default MainLayout;
