// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox, Select, Modal, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaChargingStation, FaEye, FaFacebook, FaHospital, FaInstagram, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearch, FaSearchLocation, FaTwitter, FaUsers } from 'react-icons/fa';
import { MdArrowBack, MdArrowLeft, MdBusinessCenter, MdCategory, MdContactEmergency, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowRight, MdLocalPolice, MdLocalTaxi, MdLocationPin, MdMenu, MdMenuOpen, MdOutlineKeyboardArrowRight, MdPolicy, MdTravelExplore, MdTrendingUp, MdVisibility } from 'react-icons/md';
import '../Components/css/explorer.css';
import '../Components/css/mobile.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getFeaturedEvents, getFeaturedLocations } from '../API/getters';
import { firebase } from '../base';
import { set } from 'date-fns';
import ItemFilter from '../Components/explore/ItemFilter';
import Fuse from 'fuse.js';

const Services = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [menu, setMenu] = React.useState(false)

    const [eventsSuggest, setEventsSuggest] = useState([])
    const [eventsResult, setEventsResult] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleMenu = () => {
        setMenu(!menu)
    }






    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <Helmet>
                    <title>Onde</title>
                    <meta name="description" content="Onde - Comunicação que gera confiança" />
                    <meta name="keywords" content="Onde, Comunicação, Marketing, Assessoria de Imprensa, oeconomico.com" />
                    <meta charset="UTF-8" />
                    <meta name="author" content="Codeware Solutions" />
                    <meta property="og:title" content="Onde" />
                    <meta property="og:description" content="Onde - Comunicação que gera confiança" />
                    <meta property="og:image" content={require('../assets/logo/add-value.png')} />
                </Helmet>
                <div className="navbar w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8 py-2' style={{ width: '90%' }}>
                        <img
                            onClick={() => navigate('/')}
                            src={require('../assets/logo/logo.png')}
                            className='w-20'
                            alt='Onde Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Inicio</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    <div className='banner-explorer'>
                        <div className='banner-content px-8 sm:px-16' style={{ width: '90%' }}>
                            <div className='welcome-div'>
                                <p className='welcome-text'>Explorar Serviços</p>
                            </div>
                            <h1 className='banner-title'>Descubra o Melhor de<br /> Moçambique</h1>
                        </div>
                    </div>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Inicio</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }
                    <div className='section-high-event'>
                        <img
                            src={require('../assets/images/ssd.png')}
                            className='icon-br-2'
                            alt='icon-br-2'
                            style={{ width: '10vw' }}
                        />
                        <div className='section-content flex flex-col gap-6'>
                            <p className='title-she'>Serviços à sua disposição</p>
                            <div className='grid grid-cols-1 sm:grid-cols-4 gap-8'>
                                <dic className='card-service gap-4 py-8'>
                                    <FaHospital size={70} color='#4514a5' />
                                    <p className='service-title'>Saúde</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdLocalPolice size={70} color='#4514a5' />
                                    <p className='service-title'>Polícia</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdLocalTaxi size={70} color='#4514a5' />
                                    <p className='service-title'>Taxi</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdTravelExplore size={70} color='#4514a5' />
                                    <p className='service-title'>Agência de Viagem</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdBusinessCenter size={70} color='#4514a5' />
                                    <p className='service-title'>Governo</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdContactEmergency size={70} color='#4514a5' />
                                    <p className='service-title'>Linha Verde</p>
                                </dic>
                            </div>

                        </div>
                    </div>
                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </CSSTransition>
    );
};

export default Services;
