// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowLeft, FaArrowRight, FaCalendarAlt, FaChargingStation, FaEye, FaFacebook, FaHospital, FaInstagram, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearchLocation, FaTwitter, FaUserCircle, FaUsers } from 'react-icons/fa';
import { MdArrowBack, MdArrowLeft, MdBusinessCenter, MdContactEmergency, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowRight, MdLocalPolice, MdLocalTaxi, MdLocationPin, MdMenu, MdMenuOpen, MdOutlineKeyboardArrowRight, MdPolicy, MdTravelExplore, MdTrendingUp, MdVisibility } from 'react-icons/md';
import '../Components/css/home.css';
import '../Components/css/mobile.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getEventById, getFeaturedEvents, getFeaturedLocations, getPlaceById } from '../API/getters';
import { set } from 'date-fns';
import { GoogleMap, Marker, LoadScript, useJsApiLoader } from "@react-google-maps/api";


const PlaceLayout = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [menu, setMenu] = React.useState(false)
    const { id } = useParams();
    const [event, setEvent] = React.useState(null)

    useEffect(() => {
        window.scrollTo(0, 0);
        getDados()
    }, []);

    const getDados = async () => {
        try {
            setLoading(true)
            const response = await getPlaceById(id)
            console.log(response)
            setEvent(response)
            setLocation({ lat: response.lat, lng: response.lng })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleMenu = (rota) => {
        setMenu(!menu)
        if (rota.length > 0) { navigate(rota) }
    }

    const timestampToDate = (timestamp) => {
        const date = new Date(timestamp?.seconds * 1000);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    //Map the rows
    const [location, setLocation] = useState({ lat: -25.953724, lng: 32.585789 });

    const mapStyles = {
        height: "40vh",
        width: "100%",
        marginTop: "20px",
        borderRadius: "10px"
    };

    const libraries = ['geometry', 'drawing', 'places'];

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAKPsdDL8JAlXm2RFPrmCJKymcqcGLqNoA',
        libraries
    });

    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <Helmet>
                    <title>Onde</title>
                    <meta name="description" content="Onde - Comunicação que gera confiança" />
                    <meta name="keywords" content="Onde, Comunicação, Marketing, Assessoria de Imprensa, oeconomico.com" />
                    <meta charset="UTF-8" />
                    <meta name="author" content="Codeware Solutions" />
                    <meta property="og:title" content="Onde" />
                    <meta property="og:description" content="Onde - Comunicação que gera confiança" />
                    <meta property="og:image" content={require('../assets/logo/add-value.png')} />
                </Helmet>
                <div className="navbar2 w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8 py-2' style={{ width: '90%' }}>
                        <img
                            onClick={() => navigate('/')}
                            src={require('../assets/logo/logo.png')}
                            className='w-20'
                            alt='Onde Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Inicio</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Inicio</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }

                    <div className='flex flex-col w-full sm:w-3/4 mx-auto px-4 py-8'>
                        <div className='flex flex-row items-center gap-4 mb-4 cursor-pointer' onClick={() => navigate('/explorar/lugares')}>
                            <MdArrowBack size={30} color='#584cf4' onClick={() => navigate('/explorar/lugares')} />
                            <p className='event-title'>Explorar</p>
                        </div>
                        {loading && <Spin size='large' />}
                        {event !== null &&
                            <>
                                <img
                                    src={event?.coverImage}
                                    className='imgLayout'
                                    alt={event?.name}
                                />
                                <p className='title-card mt-8 mb-3'>{event?.name}</p>
                                <p className='text-card'>{event?.description}</p>
                                <p className='subtitle-card mt-2 mb-3'>Contacto</p>
                                <div className='flex flex-row items-center gap-4'>
                                    <FaPhone size={20} color='#584cf4' />
                                    <p className='text-min-card m-0'>{(event?.phone)}</p>
                                </div>
                                <p className='subtitle-card mt-8 mb-3'>Local</p>
                                <div className='flex flex-row items-center gap-4'>
                                    <FaLocationArrow size={20} color='#584cf4' />
                                    <p className='text-min-card m-0'>{event?.location}</p>
                                </div>
                                {isLoaded &&
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        zoom={13}
                                        center={location}
                                    >
                                        <Marker 
                                        position={{lat:location.lat, lng:location.lng}}
                                         />
                                    </GoogleMap>
                                }
                                <p className='subtitle-card mt-8 mb-3'>Tags</p>
                                <div className='flex flex-wrap gap-4'>
                                    {event?.hashtags?.split(' ').map((tag, index) => (
                                        <span key={index} className='flex flex-row items-center gap-2 bg-gray-200 rounded-full px-5 py-3'>
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            </>
                        }
                    </div>

                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default PlaceLayout;
