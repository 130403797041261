import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Pages/home';
import Contact from './Pages/contact';
import MainLayout from './Layouts/MainLayout';
import Explorer from './Pages/events';
import EventLayout from './Pages/eventLayout';
import Places from './Pages/places';
import PlaceLayout from './Pages/placeLayout';
import Events from './Pages/events';
import Explore from './Pages/explore';
import Services from './Pages/services';
import About from './Pages/aboutus';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children:[
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/explorar',
        element: <Explore />,
      },
      {
        path: '/explorar/lugares',
        element: <Places />,
      },
      {
        path: '/explorar/eventos',
        element: <Events />,
      },
      {
        path: '/evento/:id',
        element: <EventLayout />,
      },
      {
        path: '/local/:id',
        element: <PlaceLayout />,
      },
      {
        path: '/servicos',
        element: <Services />,
      },
      {
        path: '/sobre-nos',
        element: <About />,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}/>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
