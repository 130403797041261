import React from 'react';
import './ItemFilter.css';

const ItemFilter = ({ titulo, selecionado, onPress }) => {
  const backgroundColor = selecionado ? '#495d92' : '#c4d1f2';
  const color = selecionado ? '#fcfcfc' : 'black';

  return (
    <div className="item-container" onClick={onPress} style={{ backgroundColor }}>
      <span className="item-text" style={{ color }}>{titulo}</span>
    </div>
  );
};

export default ItemFilter;
