import {firebase} from '../base';

export const getFeaturedEvents = async () => {
    let dados = [];
    try {
        const today = firebase.firestore.Timestamp.fromDate(new Date());
        const snapshot = await firebase.firestore()
            .collection('evento')
            .where('destaque', '==', true)
            .limit(20)
            .get()
        if (snapshot.empty) {
            return
        }
        let events = snapshot.docs.map(doc => {
            return {
                ...doc.data(),
                id: doc.id
            };
        }).slice(0, 4);
        events = events.filter((item) => !item.deleted);
        dados = events;
    } catch (error) {
        console.log(error)
    }

    return dados;
}

export const getEventById = async (id) => {
    try {
        const snapshot = await firebase.firestore()
            .collection('evento')
            .doc(id)
            .get()
        if (!snapshot.exists) {
            return
        }
        let event = snapshot.data();
        return event;
    } catch (error) {
        console.log(error)
        return
    }
}

export const getPlaceById = async (id) => {
    try {
        const snapshot = await firebase.firestore()
            .collection('local')
            .doc(id)
            .get()
        if (!snapshot.exists) {
            return
        }
        let event = snapshot.data();
        return event;
    } catch (error) {
        console.log(error)
        return
    }
}

export const getFeaturedLocations = async () => {
    try {
        const today = firebase.firestore.Timestamp.fromDate(new Date());
        const snapshot = await firebase.firestore()
            .collection('local')
            .where('destaque', '==', true)
            .limit(20)
            .get()
        if (snapshot.empty) {
            return []
        }
        let events = snapshot.docs.map(doc => {
            return {
                ...doc.data(),
                id: doc.id
            };
        }
        );
        events = events.filter((item) => !item.deleted);

        return events;

    } catch (error) {
        console.log(error)
        return []
    }
}