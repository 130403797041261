// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox, Select, Modal, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaChargingStation, FaEye, FaFacebook, FaHospital, FaInstagram, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearch, FaSearchLocation, FaTwitter, FaUsers } from 'react-icons/fa';
import { MdArrowBack, MdArrowLeft, MdBusinessCenter, MdCategory, MdContactEmergency, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowRight, MdLocalPolice, MdLocalTaxi, MdLocationPin, MdMenu, MdMenuOpen, MdOutlineKeyboardArrowRight, MdPolicy, MdTravelExplore, MdTrendingUp, MdVisibility } from 'react-icons/md';
import '../Components/css/explorer.css';
import '../Components/css/mobile.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getFeaturedEvents, getFeaturedLocations } from '../API/getters';
import { firebase } from '../base';
import { set } from 'date-fns';
import ItemFilter from '../Components/explore/ItemFilter';
import Fuse from 'fuse.js';

const Places = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [menu, setMenu] = React.useState(false)

    const [eventsSuggest, setEventsSuggest] = useState([])
    const [eventsResult, setEventsResult] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCategories();
        getSuggestEvents();
    }, []);


    //Suggested Events
    const [result, setResult] = useState(true)

    const getSuggestEvents = async () => {
        setLoading(true)
        setEventsSuggest([])
        try {
            let events = [];
            let lastEvent = null;

            // Loop para obter os dados em lotes de 20 até o fim dos documentos
            while (true) {
                let query = firebase.firestore().collection('local');

                if (lastEvent) {
                    query = query.startAfter(lastEvent);
                }

                const snapshot = await query.limit(15).get();

                if (snapshot.empty) {
                    break;
                }

                // Mapeia os documentos para o formato desejado e adiciona à lista de eventos
                snapshot.docs.forEach(doc => {
                    events.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });

                // Define o último evento para a próxima consulta
                lastEvent = snapshot.docs[snapshot.docs.length - 1];

                // Se o número de documentos retornados for menor que 20, isso indica que não há mais documentos a serem recuperados
                if (snapshot.docs.length < 15) {
                    break;
                }

                // Se tivermos 20 eventos, definimos os eventos sugeridos
                if (events.length >= 15) {
                    // Filtra eventos excluídos
                    let filteredEvents = events.filter((item) => !item.deleted);


                    // Filtra por local
                    //filteredEvents = filteredEvents.filter(item => item.location.toLowerCase().includes(chooseLocation.name.toLowerCase()));

                    // Filtra por categorias selecionadas
                    if (selectedCategories.length > 0) {
                        filteredEvents = filteredEvents.filter(item => {
                            if (Array.isArray(item?.category)) {
                                // Se a categoria for um array, verifique se alguma das categorias está selecionada
                                return item.category.some(cat => selectedCategories.includes(cat.id));
                            } else {
                                // Se a categoria for um objeto, verifique se a categoria está selecionada
                                return selectedCategories.includes(item?.category?.id);
                            }
                        });
                    }

                    // Define os eventos sugeridos e reseta a lista para o próximo lote
                    setEventsSuggest(prevEvents => [...prevEvents, ...shuffleArray(filteredEvents)]);
                    events = [];
                }
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setResult(false);
        }
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    //Search Bar
    const [search, setSearch] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    //categories
    const [modalCategory, setModalCategory] = useState(false)
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])

    const handleItemPress = (id, name) => { //Escolher uma categoria
        setCategories(categories.map(item =>
            item.id === id ? { ...item, selected: !item.selected } : item
        ));
        if (selectedCategories == id) {
            setSelectedCategories(selectedCategories.filter(item => item !== id))
        } else {
            setSelectedCategories([...selectedCategories, id])
        }
    };

    const handleCategory = () => {
        setModalCategory(!modalCategory)
    }

    //Apply Filters
    useEffect(() => {
        if (selectedCategories.length === 0) {
            if (searchValue.length > 0) {
                goSearch()
                getSuggestEvents()
            }
            else {
                getSuggestEvents()
            }
        }
    }, [selectedCategories]);

    const applyFilters = () => {
        setModalCategory(false)
        if (searchValue.length > 0) {
            goSearch(); getSuggestEvents()
        }
        else {
            getSuggestEvents()
        }
    }

    //Search
    const goSearch = async () => {
        if (searchValue.length > 0) {
            setLoading(true)
            try {
                const today = firebase.firestore.Timestamp.fromDate(new Date());
                let query = firebase.firestore().collection('local');
                query = query.orderBy('views', 'desc')

                const snapshot = await query.get();
                console.log(snapshot.size)

                if (!snapshot.empty) {
                    let events = snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        };
                    });
                    events = events.filter((item) => !item.deleted);
                    const fuse = new Fuse(events, {
                        keys: ['name', 'category.name', 'hashtags']
                    });
                    let fil = fuse.search(searchValue);

                    let result = fil.map(item => item.item)

                    //Location
                    //locationFilter = result.filter(item => item.location.toLowerCase().includes(chooseLocation.name.toLowerCase()))
                    //result = locationFilter

                    //Categories
                    console.log(selectedCategories)
                    if (selectedCategories.length > 0) {
                        result = result.filter(item => {
                            if (Array.isArray(item?.category)) {
                                // Se a categoria for um array, verifique se alguma das categorias está selecionada
                                return item.category.some(cat => selectedCategories.includes(cat.id));
                            } else {
                                // Se a categoria for um objeto, verifique se a categoria está selecionada
                                return selectedCategories.includes(item?.category?.id);
                            }
                        });
                    }

                    setEventsResult(result)
                }
                setResult(true)
            }
            catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

    }



    const fetchCategories = async () => {
        try {
            const snapshot = await firebase.firestore()
                .collection('categoriaLocal')
                .get()
            if (snapshot.empty) {
                return
            }
            const categories = snapshot.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    selected: false
                };
            });
            setCategories(categories)
        } catch (error) {
            console.log(error)
        }
    }

    const handleMenu = (path) => {
        setMenu(!menu)
        if (path) {
            navigate(path)
        }
    }

    const trimWords = (text) => {
        if (text.length > 100) {
            return text.substring(0, 100) + '...'
        } else {
            return text
        }
    }


    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <Helmet>
                    <title>Onde</title>
                    <meta name="description" content="Onde - Comunicação que gera confiança" />
                    <meta name="keywords" content="Onde, Comunicação, Marketing, Assessoria de Imprensa, oeconomico.com" />
                    <meta charset="UTF-8" />
                    <meta name="author" content="Codeware Solutions" />
                    <meta property="og:title" content="Onde" />
                    <meta property="og:description" content="Onde - Comunicação que gera confiança" />
                    <meta property="og:image" content={require('../assets/logo/add-value.png')} />
                </Helmet>
                <div className="navbar w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8 py-2' style={{ width: '90%' }}>
                        <img
                            onClick={() => navigate('/')}
                            src={require('../assets/logo/logo.png')}
                            className='w-20'
                            alt='Onde Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Inicio</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    <div className='banner-explorer'>
                        <div className='banner-content px-8 sm:px-16' style={{ width: '90%' }}>
                            <div className='welcome-div'>
                                <p className='welcome-text'>Explorar Lugares</p>
                            </div>
                            <h1 className='banner-title'>Descubra o Melhor de<br /> Moçambique</h1>
                        </div>
                    </div>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Inicio</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }
                    <div className='section-explore'>
                        <div className='section-explore-content'>
                            <div className='w-full flex flex-row gap-8 sm:gap-2'>
                                <input
                                    placeholder='Pesquisar Locais'
                                    className='search-input w-1/4 mr-3'
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onBlur={() => {
                                        setTimeout(() => {
                                            setSearch(false)
                                            goSearch()
                                        }, 500)
                                    }}
                                    onFocus={() => setSearch(true)}

                                >
                                </input>
                                <div className='search-select px-4 cursor-pointer' onClick={handleCategory}>
                                    <MdCategory size={20} color='white' />
                                    Categorias
                                    <MdKeyboardArrowDown size={20} color='white' />
                                </div>
                                <div className='search-select px-4'>
                                    Local
                                    <MdKeyboardArrowDown size={20} color='white' />
                                </div>
                                <div className='search-select px-4'>
                                    Data
                                    <MdKeyboardArrowDown size={20} color='white' />
                                </div>
                            </div>
                            {loading &&
                                <>
                                    <Spin size='large' />
                                </>
                            }
                            {eventsSuggest.length === 0 && !loading && searchValue.length === 0 &&
                                <div className='w-full flex flex-col justify-center items-center py-12 '>
                                    <img
                                        src={require('../assets/images/empty.png')}
                                        className='w-50'
                                        alt='Loading'
                                    />
                                    <p className='text-nf mt-10'>
                                        Não foram encontrados eventos
                                    </p>
                                </div>
                            }
                            {eventsSuggest.length > 0 && !loading && result && searchValue.length === 0 &&
                                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 sm:gap-16 px-8 sm:px-16 my-5'>
                                    {eventsSuggest.map((item, index) =>
                                        <div className='card-event-explore'>
                                            <img
                                                src={item.coverImage}
                                                className='image-event'
                                            />
                                            <div className='card-event-content'>
                                                <p className='event-title'>{item.name}</p>
                                                <p className='event-text'>{trimWords(item.description)}</p>
                                            </div>
                                            <div className='mt-auto flex flex-row gap-1 items-center' onClick={() => navigate(`/local/${item.id}`)}>
                                                <MdKeyboardDoubleArrowRight size={25} color='#4514a5' />
                                                <p className='event-link'>Ver Mais</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            {eventsResult.length === 0 && !loading && searchValue.length > 0 && !search &&
                                <div className='w-full flex flex-col justify-center items-center py-12 '>
                                    <img
                                        src={require('../assets/images/empty.png')}
                                        className='w-50'
                                        alt='Loading'
                                    />
                                    <p className='text-nf mt-10'>
                                        Não foram encontrados eventos
                                    </p>
                                </div>
                            }
                            {eventsResult.length > 0 && !loading && searchValue.length > 0 &&
                                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 sm:gap-16 px-8 sm:px-16 my-5'>
                                    {eventsResult.map((item, index) =>
                                        <div className='card-event-explore'>
                                            <img
                                                src={item.coverImage}
                                                className='image-event'
                                            />
                                            <div className='card-event-content'>
                                                <p className='event-title'>{item.name}</p>
                                                <p className='event-text'>{trimWords(item.description)}</p>
                                            </div>
                                            <div className='mt-auto flex flex-row gap-1 items-center' onClick={() => navigate(`/local/${item.id}`)}>
                                                <MdKeyboardDoubleArrowRight size={25} color='#4514a5' />
                                                <p className='event-link'>Ver Mais</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>

                    <Modal
                        title="Categorias"
                        visible={modalCategory}
                        onOk={handleCategory}
                        onCancel={handleCategory}
                        footer={null}
                    >
                        <div className='flex flex-row flex-wrap gap-2'>
                            {categories.map((item, index) => (
                                <ItemFilter
                                    key={index}
                                    titulo={item.name}
                                    selecionado={item.selected}
                                    onPress={() => handleItemPress(item.id, item.name)}
                                />
                            ))}
                        </div>
                        <div className='flex flex-row justify-end mt-5'>
                            <div className='button-apply w-full' onClick={applyFilters}>
                                <p className='mid-button-title'>Aplicar</p>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Places;
