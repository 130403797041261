// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox, Select, Modal, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaChargingStation, FaEye, FaFacebook, FaHospital, FaInstagram, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearch, FaSearchLocation, FaTwitter, FaUsers } from 'react-icons/fa';
import { MdArrowBack, MdArrowLeft, MdBusinessCenter, MdCategory, MdContactEmergency, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowRight, MdLocalPolice, MdLocalTaxi, MdLocationPin, MdMenu, MdMenuOpen, MdOutlineKeyboardArrowRight, MdPolicy, MdTravelExplore, MdTrendingUp, MdVisibility } from 'react-icons/md';
import '../Components/css/explorer.css';
import '../Components/css/mobile.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getFeaturedEvents, getFeaturedLocations } from '../API/getters';
import { firebase } from '../base';
import { set } from 'date-fns';
import ItemFilter from '../Components/explore/ItemFilter';
import Fuse from 'fuse.js';

const Explore = () => {
    const navigate = useNavigate();

    const [menu, setMenu]= useState(false)

    const handleMenu = (path) => {
        setMenu(!menu)
        if (path) {
            navigate(path)
        }
    }

    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <Helmet>
                    <title>Onde</title>
                    <meta name="description" content="Onde - Comunicação que gera confiança" />
                    <meta name="keywords" content="Onde, Comunicação, Marketing, Assessoria de Imprensa, oeconomico.com" />
                    <meta charset="UTF-8" />
                    <meta name="author" content="Codeware Solutions" />
                    <meta property="og:title" content="Onde" />
                    <meta property="og:description" content="Onde - Comunicação que gera confiança" />
                    <meta property="og:image" content={require('../assets/logo/add-value.png')} />
                </Helmet>
                <div className="navbar w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8 py-2' style={{ width: '90%' }}>
                        <img
                            onClick={() => navigate('/')}
                            src={require('../assets/logo/logo.png')}
                            className='w-20'
                            alt='Onde Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Inicio</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    <div className='banner-explorer'>
                        <div className='banner-content px-8 sm:px-16' style={{ width: '90%' }}>
                            <div className='welcome-div'>
                                <p className='welcome-text'>Explorar</p>
                            </div>
                            <h1 className='banner-title'>Descubra o Melhor de<br /> Moçambique</h1>
                        </div>
                    </div>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Inicio</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }

<div className='section-explore'>
                        <img
                            src={require('../assets/images/sea.png')}
                            className='icon-bl'
                            alt='icon-bl'
                        />
                        <div className='section-content flex flex-col gap-6'>
                            <p className='title-shl'>Explore</p>
                            <div className='grid grid-cols-1 sm:grid-cols-3 gap-8'>
                                <div className='card-explore'>
                                    <img
                                        src={'https://www.melhoresdestinos.com.br/wp-content/uploads/2017/05/maputo-mocambique-dicas_1024-800x534.jpg'}
                                        className='image-explore'
                                    />
                                    <div className='card-explore-content'>
                                        <p className='explore-title'>Descubra novos lugares</p>
                                        <button className='explore-button' onClick={() => navigate('/explorar/lugares')}>
                                            <p className='explore-text'>Locais</p>
                                        </button>
                                    </div>
                                </div>
                                <div className='card-explore'>
                                    <img
                                        src={'https://danatours.com/wp-content/uploads/2016/03/concert-768722_1920-1-1.jpg'}
                                        className='image-explore'
                                    />
                                    <div className='card-explore-content'>
                                        <p className='explore-title'>Pesquise eventos</p>
                                        <button className='explore-button' onClick={() => navigate('/explorar/eventos')}>
                                            <p className='explore-text'>Eventos</p>
                                        </button>
                                    </div>
                                </div>
                                <div className='card-explore '>
                                    <img
                                        src={'https://opais.co.mz/wp-content/uploads/2022/05/MAPUTO.jpg'}
                                        className='image-explore'
                                    />
                                    <div className='card-explore-content'>
                                        <p className='explore-title'>Encontre os melhores serviços </p>
                                        <button className='explore-button' onClick={() => navigate('/servicos')}>
                                            <p className='explore-text'>Serviços</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Explore;
