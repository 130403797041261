// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowLeft, FaArrowRight, FaChargingStation, FaEye, FaFacebook, FaHospital, FaInstagram, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearchLocation, FaTwitter, FaUsers } from 'react-icons/fa';
import { MdArrowBack, MdArrowLeft, MdBusinessCenter, MdContactEmergency, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowRight, MdLocalPolice, MdLocalTaxi, MdLocationPin, MdMenu, MdMenuOpen, MdOutlineKeyboardArrowRight, MdPolicy, MdTravelExplore, MdTrendingUp, MdVisibility } from 'react-icons/md';
import '../Components/css/home.css';
import '../Components/css/mobile.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getFeaturedEvents, getFeaturedLocations } from '../API/getters';
import { set } from 'date-fns';

const Home = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [menu, setMenu] = React.useState(false)

    const [topEvents, setTopEvents] = React.useState([])
    const [topLocations, setTopLocations] = React.useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        getDados()
    }, []);

    const getDados = async () => {
        let dadosL = await getFeaturedLocations()
        setTopLocations(dadosL)
        console.log(dadosL)
        let dados = await getFeaturedEvents()
        setTopEvents(dados)
    }

    const handleMenu = (rota) => {
        setMenu(!menu)
        if (rota.length > 0) { navigate(rota) }
    }

    const trimWords = (text) => {
        if (text.length > 100) {
            return text.substring(0, 100) + '...'
        } else {
            return text
        }
    }

    //slide 1
    const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentLocationIndex((currentLocationIndex + 1) % topLocations.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [currentLocationIndex, topLocations.length]);

    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <Helmet>
                    <title>Onde</title>
                    <meta name="description" content="Onde - Comunicação que gera confiança" />
                    <meta name="keywords" content="Onde, Comunicação, Marketing, Assessoria de Imprensa, oeconomico.com" />
                    <meta charset="UTF-8" />
                    <meta name="author" content="Codeware Solutions" />
                    <meta property="og:title" content="Onde" />
                    <meta property="og:description" content="Onde - Comunicação que gera confiança" />
                    <meta property="og:image" content={require('../assets/logo/add-value.png')} />
                </Helmet>
                <div className="navbar w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8 py-2' style={{ width: '90%' }}>
                        <img
                            onClick={() => navigate('/')}
                            src={require('../assets/logo/logo.png')}
                            className='w-20'
                            alt='Onde Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Inicio</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    <div className='banner'>
                        <div className='banner-content px-8 sm:px-16' style={{ width: '90%' }}>
                            <div className='welcome-div'>
                                <p className='welcome-text'>BEM-VINDO</p>
                            </div>
                            <h1 className='banner-title'>Pesquisa & Descobre <br /> Moçambique</h1>
                            <div className='banner-button' onClick={() => navigate('/explorar')}>
                                <p className='bb-text'>EXPLORAR</p>
                            </div>
                        </div>
                    </div>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Inicio</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/explorar')}>Explorar</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>

                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }
                    <div className='section-high-local'>
                        <img
                            src={require('../assets/images/shl.png')}
                            className='icon-tl'
                            alt='icon-tl'
                        />
                        <img
                            src={require('../assets/images/shl.png')}
                            className='icon-br'
                            alt='icon-br'
                        />
                        <div className='section-content flex flex-col sm:flex-row gap-4'>
                            <div className='w-full sm:w-1/3 flex flex-col pt-2 sm:pt-12 pb-4 justify-between'>
                                <p className='title-shl'>Lugares <br />Em Destaque</p>
                                <div className='flex flex-row align-center gap-2'>
                                    <MdLocationPin className='pin' size={50} color='#4514a5' />
                                    <p className='text-shl'>{topLocations[currentLocationIndex]?.name}, {topLocations[currentLocationIndex]?.location}</p>
                                </div>
                            </div>
                            <div className='w-full sm:w-2/3 flex flex-row items-center justify-center'>
                                <MdKeyboardArrowLeft size={50} color='#4514a5' onClick={() => setCurrentLocationIndex((currentLocationIndex - 1 + topLocations?.length) % topLocations?.length)} />
                                <div className='card-image-shl' onClick={() => navigate(`/local/${topLocations[currentLocationIndex]?.id}`)}>
                                    <img
                                        src={topLocations[currentLocationIndex]?.coverImage}
                                        className='image-shl'
                                    />
                                </div>
                                <MdKeyboardArrowRight size={50} color='#4514a5' onClick={() => setCurrentLocationIndex((currentLocationIndex + 1) % topLocations?.length)} />
                            </div>
                        </div>
                    </div>
                    {topEvents.length > 0 &&
                        <div className='section-high-event'>
                            <img
                                src={require('../assets/images/she1.png')}
                                className='icon-tl-2'
                                alt='icon-tl'
                            />
                            <img
                                src={require('../assets/images/she2.png')}
                                className='icon-br-2'
                                alt='icon-br-2'
                            />
                            <div className='section-content flex flex-col gap-6'>
                                <p className='title-she'>Conheça os Melhores Eventos</p>
                                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8'>
                                    {topEvents.slice(0, 4).map((item, index) =>
                                        <div className='card-event' onClick={() => navigate(`/evento/${item.id}`)}>
                                            <img
                                                src={item.coverImage}
                                                className='image-event'
                                            />
                                            <div className='card-event-content'>
                                                <p className='event-title'>{item.name}</p>
                                                <p className='event-text'>{trimWords(item.description)}</p>
                                            </div>
                                            <div className='mt-auto flex flex-row gap-1 items-center'>
                                                <MdKeyboardDoubleArrowRight size={25} color='#4514a5' />
                                                <p className='event-link'>Ver Mais</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                    <div className='section-explore'>
                        <img
                            src={require('../assets/images/sea.png')}
                            className='icon-bl'
                            alt='icon-bl'
                        />
                        <div className='section-content flex flex-col gap-6'>
                            <p className='title-shl'>Actividades</p>
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                                <div className='card-explore'>
                                    <img
                                        src={'https://www.melhoresdestinos.com.br/wp-content/uploads/2017/05/maputo-mocambique-dicas_1024-800x534.jpg'}
                                        className='image-explore'
                                    />
                                    <div className='card-explore-content'>
                                        <p className='explore-title'>Descubra novos lugares</p>
                                        <button className='explore-button' onClick={() => navigate('/explorar/lugares')}>
                                            <p className='explore-text'>Locais</p>
                                        </button>
                                    </div>
                                </div>
                                <div className='card-explore'>
                                    <img
                                        src={'https://danatours.com/wp-content/uploads/2016/03/concert-768722_1920-1-1.jpg'}
                                        className='image-explore'
                                    />
                                    <div className='card-explore-content'>
                                        <p className='explore-title'>Pesquise eventos</p>
                                        <button className='explore-button' onClick={() => navigate('/explorar/eventos')}>
                                            <p className='explore-text'>Eventos</p>
                                        </button>
                                    </div>
                                </div>
                                <div className='card-explore '>
                                    <img
                                        src={'https://opais.co.mz/wp-content/uploads/2022/05/MAPUTO.jpg'}
                                        className='image-explore'
                                    />
                                    <div className='card-explore-content'>
                                        <p className='explore-title'>Encontre os melhores serviços </p>
                                        <button className='explore-button' onClick={() => navigate('/servicos')}>
                                            <p className='explore-text'>Serviços</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section-high-event'>
                        <img
                            src={require('../assets/images/ssd.png')}
                            className='icon-br-2'
                            alt='icon-br-2'
                            style={{ width: '10vw' }}
                        />
                        <div className='section-content flex flex-col gap-6'>
                            <p className='title-she'>Serviços à sua disposição</p>
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8'>
                                <dic className='card-service gap-4 py-8'>
                                    <FaHospital size={70} color='#4514a5' />
                                    <p className='service-title'>Saúde</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdLocalPolice size={70} color='#4514a5' />
                                    <p className='service-title'>Polícia</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdLocalTaxi size={70} color='#4514a5' />
                                    <p className='service-title'>Taxi</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdTravelExplore size={70} color='#4514a5' />
                                    <p className='service-title'>Agência de Viagem</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdBusinessCenter size={70} color='#4514a5' />
                                    <p className='service-title'>Governo</p>
                                </dic>
                                <dic className='card-service gap-4 py-8'>
                                    <MdContactEmergency size={70} color='#4514a5' />
                                    <p className='service-title'>Linha Verde</p>
                                </dic>
                            </div>

                        </div>
                    </div>
                    <div className='section-phone'>
                        <img
                            src={require('../assets/images/line1.png')}
                            className='icon-bl'
                            style={{ width: '20vw' }}
                        />
                        <div className='section-content flex flex-col sm:flex-col lg:flex-row gap-6'>
                            <div className='w-full lg:w-1/3 flex flex-col justify-center items-center'>
                                <img
                                    src={require('../assets/images/phone.png')}
                                    className='phone-image'
                                    style={{ height: '100%' }}
                                />
                            </div>
                            <div className='w-full lg:w-2/3 flex flex-col justify-center gap-6'>
                                <p className='title-shl'>Conheça o nosso aplicativo</p>
                                <p className='text-shl-2'>Bem-vindo à nossa plataforma, a tua porta de entrada para desbloquear a essência turística do nosso belo Moçambique.
                                    Fundada com uma paixão pela aventura e um profundo apreço pela cultura local, somos uma equipa de entusiastas dedicados e empenhados em mostrar
                                    o que de melhor o nosso país tem a oferecer. A nossa missão é simples: inspirar a exploração, promover conexões e criar experiências memoráveis
                                    para todos os visitantes e locais.
                                </p>
                                <div className='flex flex-row gap-4 grid grid-cols-2 sm:grid-cols-4'>
                                    <img
                                        src={require('../assets/images/playstore.png')}
                                        className='store-image'
                                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.ondeapp&pcampaignid=web_share')}
                                    />
                                    <img
                                        src={require('../assets/images/applestore.png')}
                                        className='store-image'
                                        onClick={() => window.open('https://apps.apple.com/us/app/onde-mz/id6503192341')}
                                    />
                                </div>
                            </div>
                        </div>
                        <img
                            src={require('../assets/images/line2.png')}
                            className='icon-tr'
                            style={{ width: '20vw' }}
                        />
                    </div>
                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Home;
